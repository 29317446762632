// @flow
import type { Dispatch } from 'redux';
import { finectAPI } from '@finect/front-utils/finectAPI';

export function setSetting(setting: string, value: any) {
  return {
    type: 'SET_SETTING',
    setting,
    value
  };
}

export function fetchSetting(id: string) {
  return (dispatch: Dispatch) =>
    finectAPI({
      version: 'v4',
      path: `resources/setting/${id}`,
      method: 'GET',
      onSuccess: ({ data }: { data: Object }) => {
        dispatch(setSetting(id, data.value));
      }
    });
}
