import styled, { css } from 'styled-components';
import { minMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';

const headingStyles = (index, mode = 'compact') => css`
  ${getFont(index, mode)}
  font-weight: 700;
  ${({ textAlign }) => (textAlign ? `text-align: ${textAlign};` : null)};
`;

export const Title1 = styled.h1`
  padding-bottom: 24px;
  ${headingStyles(6)}

  ${minMediaQueries.phablet`
    ${getFont(7, 'compact')}
  `};
`;

export const Title2 = styled.h2`
  padding-bottom: 24px;
  ${headingStyles(4)}

  ${minMediaQueries.phablet`
    ${getFont(5, 'compact')}
  `};

  @media (min-width: 1025px) {
    ${getFont(6, 'compact')}
  }

  .extra {
    display: none;
  }
`;

export const Title3 = styled.h3`
  padding-bottom: 18px;
  ${headingStyles(4)}

  ${minMediaQueries.phablet`
    ${getFont(5, 'compact')}
  `};
`;

export const Title4 = styled.h4`
  padding-bottom: 12px;
  ${headingStyles(3)}

  ${minMediaQueries.phablet`
    ${getFont(4, 'compact')}
  `};
`;

export const Title5 = styled.h5`
  padding-bottom: 12px;
  ${headingStyles(3)}
`;

export const Title6 = styled.h6`
  padding-bottom: 12px;
  ${headingStyles(2)}
`;

export const Title7 = styled.h6`
  padding-bottom: 12px;
  ${headingStyles(1, 'xcompact')}
`;
