// @flow
import styled, { css } from 'styled-components';
import { grid } from '@finect/tabular-utils/grid';
import { maxMediaQueries } from '@finect/tabular-utils/mediaQueries';
import { getFont } from '@finect/tabular-utils/getFont';
import { textEllipsis } from '@finect/tabular-utils/textEllipsis';
import { getImageUrl } from '@finect/tabular-components/Image';
import { ToolbarContent, Action } from '../components/Toolbar';

export const QuestionLabel = styled.span``;

const commonStyles = css`
  position: relative;
  flex-shrink: 0;
  overflow: hidden;
  width: ${({ size }) => size || '84px'};
  height: ${({ size }) => size || '84px'};
  border-radius: 2px;
  margin: ${({ margin }) => margin || '0 0 0 15px'};
`;

export const SquareImage = styled.div`
  ${commonStyles};
  box-shadow: 2px 2px 5px 1px rgb(58 38 136 / 0.3);

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    color: ${({ theme }) => theme.colors.primary};
    transform: translate(-50%, -50%);
  }
`;

export const SquareIcon = styled.div`
  ${commonStyles};
  background-color: ${({ theme }) => theme.colors.uiLL};
  color: ${({ theme }) => theme.colors.primary};

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
export const Answer = styled.p`
  display: block;
  font-weight: 600;
  ${getFont(0)}

  div {
    ${getFont(0, 'compact')}
    font-weight: 400;
  }
`;

// ----------------------------- VIDEO -----------------------------
/* eslint-disable */
export const VideoContainer = styled.div`
  display: flex;
  padding: 36px 0;
  border-top: 1px solid ${({ theme }) => theme.colors.uiM};

  ${/* sc-sel */ ToolbarContent} {
    display: block;
  }

  ${/* sc-sel */ Action} {
    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  > div:first-child {
    flex-shrink: 1;
    width: 100%;
    max-width: ${grid(3)}px;
    margin-right: 30px;
  }

  > div:last-child {
    flex-shrink: 2;
    width: 100%;
    max-width: ${grid(5)}px;
  }

  @media (max-width: 1100px) {
    flex-wrap: wrap;
    padding: 36px 0 9px;

    > div:first-child {
      max-width: none;
      margin-right: 0;
    }

    > div:last-child {
      max-width: none;
      margin-top: 18px;
    }

    h3 {
      ${getFont(2, 'compact')}
    }

    ${/* sc-sel */ ToolbarContent} {
      display: flex;
      margin-top: 0;
    }

    ${/* sc-sel */ Action} {
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }

  ${maxMediaQueries.phone`
    position: relative;
    padding-bottom: 48px;

    h3 {
      ${getFont(1, 'xcompact')}
    }
  `};
`;
/* eslint-enable */

export const VideoContent = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgb(46 46 51 / 0.25);
  cursor: pointer;
`;

export const VideoIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
`;

// ----------------------------- ARTICLE -----------------------------

export const Author = styled.div`
  width: auto;
  margin-top: 6px;
  ${getFont(-2, 'compact')}
  font-weight: 700;

  a,
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: currentColor;
  }
`;

/* eslint-disable */
const commonContentStyles = css`
  display: flex;

  h3 {
    ${maxMediaQueries.miniDesktop`
      ${getFont(2, 'compact')}
    `};

    ${maxMediaQueries.phone`
      ${getFont(1, 'xcompact')}
    `};

    a {
      color: currentColor;
    }
  }

  ${maxMediaQueries.phone`
    position: relative;
    padding-bottom: 60px;
  `};

  ${/* sc-sel */ SquareImage} {
    ${maxMediaQueries.miniDesktop`
      width: 114px;
      height: 114px;
    `};

    ${maxMediaQueries.phone`
      width: 84px;
      height: 84px;
    `};
  }

  ${/* sc-sel */ SquareIcon} {
    ${maxMediaQueries.miniDesktop`
      width: 114px;
      height: 114px;

      svg {
        width: 60px;
        height: 60px;
        stroke-width: 3.8;
      }
    `};

    ${maxMediaQueries.phone`
      display: none;
    `};
  }
`;

export const ArticleContent = styled.div`
  ${commonContentStyles};
  justify-content: space-between;
  padding: 36px 0 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.uiM};
  ${maxMediaQueries.phablet`
    padding: 48px 0;
  `};

  &:first-of-type {
    padding: 20px 0 24px;
    border-top: none;
    ${maxMediaQueries.phablet`
      padding: 24px 0 48px;
    `};
  }

  h3 {
    padding-bottom: 6px;

    span {
      color: ${({ theme }) => theme.colors.primary};
    }
  }

  small {
    strong {
      margin-right: 6px;
    }

    span {
      margin-right: 6px;
    }
  }
`;

export const ArticleInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  max-width: ${({ hasImage }) => (hasImage ? `${grid(8)}px` : `${grid(12)}px`)};
  padding-right: ${({ hasImage }) => (hasImage ? `30px` : ``)};

  ${maxMediaQueries.phone`
    padding-right: ${({ hasImage }) => (hasImage ? '5px' : '')};
  `};
`;

export const Small = styled.small`
  display: block;
  margin: 5px 0 0 ${({ withLeftMargin = true }) => (withLeftMargin ? '8px' : '0')};
  ${getFont(-1, 'compact')}
  color: ${({ theme }) => theme.colors.terciary};
`;

export const External = styled.a`
  display: inline-block;
  position: relative;
  overflow: hidden;
  max-width: 320px;
  border-radius: 18px;
  margin-right: 9px;
  color: ${({ theme }) => theme.colors.fontL};
  ${textEllipsis};

  span {
    vertical-align: middle;
    ${getFont(-1, 'compact')}
  }

  svg {
    margin-right: 4px;
    vertical-align: middle;
  }
`;

export const ExternalImage = styled.div`
  width: 100%;
  height: 120px;
  box-shadow: 2px 2px 5px 1px rgb(60 68 215 / 0.3);
  margin-bottom: 20px;
  background-image: ${({ src }) => `url(${getImageUrl(src)})`};
  background-position: center;
  background-size: cover;
`;

export const PublishDate = styled(Small)`
  ${getFont(-2, 'compact')}
  color: ${({ theme }) => theme.colors.terciary};
`;
