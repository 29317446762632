//@flow
export function settings(
  state: Object = {
    settings: {}
  },
  action: Object
) {
  switch (action.type) {
    case 'SET_SETTING':
      return {
        ...state,
        settings: {
          ...state.settings,
          [action.setting]: action.value
        }
      };

    default:
      return state;
  }
}
