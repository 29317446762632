// @flow
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Ads } from '@finect/tabular-components/Ads';
import { Inner, Block, BlockContent } from './partials';

type Props = {
  left?: string,
  right?: string,
  targeting?: Object,
  withInsideNavigation?: boolean
};

export const LateralAds = ({
  left = 'Finect_Side3',
  right = 'Finect_Side4',
  targeting = {},
  withInsideNavigation
}: Props) => {
  if (typeof window === 'undefined') {
    return null;
  }

  // 1286 = x + 36*2
  // if (!(window.innerWidth >= 1538)) {
  // if (!(window.innerWidth >= 1458)) {
  if (!(window.innerWidth >= 1200)) {
    return null;
  }

  const node = useRef();

  const omninavState: "opened" | "closed" = useSelector(state => state.omninav.state);

  const parentElement = node.current && node.current.parentElement;
  const parentHeight = parentElement && parentElement.clientHeight;

  const adsHeight = parentHeight !== null ? parentHeight : 1400;

  return (
    <Inner omninavState={omninavState} withInsideNavigation={withInsideNavigation} ref={node}>
      <Block position="left" height={adsHeight}>
        <BlockContent>
          <Ads id={left} targeting={targeting} />
        </BlockContent>
      </Block>

      <Block position="right" height={adsHeight}>
        <BlockContent>
          <Ads id={right} targeting={targeting} />
        </BlockContent>
      </Block>
    </Inner>
  );
};
